import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BusinessClient } from '../../../interfaces';

interface Props {
  client?: BusinessClient,
}

const initialState: Props = {
  client: undefined,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setClient(state: Props, { payload }: PayloadAction<BusinessClient>) {
      state.client = payload;
    },
    updateClient(state: Props, { payload }: PayloadAction<any>) {
      state.client = {
        ...state.client,
        ...payload
      };
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setClient,
  updateClient,
} = clientsSlice.actions;

export default clientsSlice.reducer;
