import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Props {
  hasPendingWhatsAppOrders: Boolean,
  hasUnreadWhatsappUserMessages: Boolean,
}

const initialState: Props = {
  hasPendingWhatsAppOrders: false,
  hasUnreadWhatsappUserMessages: false,
};

export const slice = createSlice({
  name: 'dashbaord',
  initialState,
  reducers: {
    setHasPendingWhatsAppOrders: (state: Props, { payload }: PayloadAction<any>) => {
      state.hasPendingWhatsAppOrders = payload;
    },
    setHasUnreadWhatsappUserMessages: (state: Props, { payload }: PayloadAction<any>) => {
      state.hasUnreadWhatsappUserMessages = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setHasPendingWhatsAppOrders,
  setHasUnreadWhatsappUserMessages
} = slice.actions;

export default slice.reducer;
