/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/react';
import store from './store';
// import store from './redux/store';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
  gtmId: 'GTM-PW3LZ99'
};

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  TagManager.initialize(tagManagerArgs);
}

const App = lazy(() => import(/* webpackChunkName: "App" */ './App'));

const persistor = persistStore(store);


Sentry.init({
  dsn: 'https://b00c4d5525e1449baf16fa2e26ef189d@o1307009.ingest.sentry.io/4505160418263040',
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENVIRONMENT === 'production' ? 'production' : 'development',
});

const Main = () => (
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div className="loading" />}>
          <App />
        </Suspense>
      </PersistGate>
    </Provider>
  </>
);

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
