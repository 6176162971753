import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCurrentLanguage, setCurrentLanguage } from '../../../helpers/Utils';

interface Props {
  locale: string,
}

const initialState: Props = {
  locale: getCurrentLanguage(),
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    changeLocale: (state: Props, { payload }: PayloadAction<any>) => {
      setCurrentLanguage(payload);
      state.locale = payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { changeLocale } = settingsSlice.actions;

export default settingsSlice.reducer;
