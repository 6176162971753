import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup';
import { BusinessClient } from '../../../interfaces';

interface Props {
  messages: [],
  hasNewMessages: boolean,
  hasNewPublicMessages: boolean,
}

// interface Message {
//   text: string,
// };
const initialState: Props = {
  messages: [],
  hasNewMessages: false,
  hasNewPublicMessages: false,
};

export const chatSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    setMessages(state: Props, { payload }: PayloadAction<any>) {
      state.messages = payload;
    },
    setHasNewMessages(state: Props, { payload }: PayloadAction<any>) {
      state.hasNewMessages = payload;
    },
    setHasNewPublicMessages(state: Props, { payload }: PayloadAction<any>) {
      state.hasNewPublicMessages = payload;
    },
  }
});

// Action creators are generated for each case reducer function
export const {
  setMessages,
  setHasNewMessages,
  setHasNewPublicMessages,
} = chatSlice.actions;

export default chatSlice.reducer;
