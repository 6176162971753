import { combineReducers } from '@reduxjs/toolkit';
import auth from './slices/auth';
import business from './slices/business';
import clients from './slices/clients';
import menu from './slices/menu';
import settings from './slices/settings';
import chat from './slices/chat';
import dashboard from './slices/dashboard';

export const rootReducer = combineReducers({
  auth,
  business,
  clients,
  menu,
  settings,
  chat,
  dashboard,
});
